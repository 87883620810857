import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) { }
  userbasePath = environment.apiUrl + "Account";
  basePath = environment.apiUrl + 'Users/';
  connectionBasePath = this.basePath + "ConnectUser";
  getAllUsers(): any {
    return this.http.get<any>(this.userbasePath + "/All");
  }

  getUser(id): any {
    return this.http.get<any>(this.userbasePath + "/Get/" + id);
  }

  getUserFirstAndLastName(id): any {
    return this.http.get<any>(this.userbasePath + "/GetUserFirstAndLastName/" + id);
  }

  getUserId(): any {
    return this.http.get<any>(this.userbasePath + "/GetUserId");
  }
  getToken(): string {
    return localStorage.getItem("token")
  }
  create(body): any {
    return this.http.post<any>(this.userbasePath + "/Register", body);
  }
  CheckSession(): any {
    return this.http.post<any>(this.userbasePath + "/CheckSession", {});
  }
  //-----//
  sendMailInvitation(body): any {
    return this.http.post<any>(this.userbasePath + "/SendInvitation", body);
  }
  //-----//
  update(body): any {
    return this.http.post<any>(this.userbasePath + "/Update", body);
  }

  UpdateUserFirstAndLastName(body): any {
    return this.http.post<any>(this.userbasePath + "/UpdateUserFirstAndLastName", body);
  }

  delete(body): any {
    return this.http.post<any>(this.userbasePath + "/Delete", body);
  }
  resetPassword(body): any {
    return this.http.post<any>(this.userbasePath + "/ResetPassword", body);
  }
  LinkToTenant(name: string): any {
    const body = {
      email: name,
    };
    return this.http.post<any>(this.userbasePath + "/LinkToTenant", body);
  }
  Logout(): any {
    return this.http.post<any>(this.userbasePath + "/Logout", {});
  }
  ConnectUser(data): Observable<any> {
    return this.http.post<any>(this.userbasePath + "/ConnectUser", data);
  }
  getTenantId(): any {
    return localStorage.getItem("currentTenantId");
  }
  getUserIdFromStorage(): any {
    return localStorage.getItem("userId");
  }
  getEmailFromStorage(): any {
    return localStorage.getItem("email");
  }
  getPermission(): any {
    const permissions = localStorage.getItem("permission");
    if (!permissions) {
      return []; // Return an empty array if no permissions found
    }
  
    return permissions
      .replace(/[[\]']+/g, "") // Remove brackets and single quotes
      .split(",") // Split by comma
      .map(Number); 
  }
}

export function getRefreshTokenFromLocalStorage(clientId: string): string {
  const lastUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
  const lastUser = localStorage.getItem(lastUserKey);
  const refreshTokenKey = `CognitoIdentityServiceProvider.${clientId}.${lastUser}.refreshToken`;
  return localStorage.getItem(refreshTokenKey);
}