<div class="d-flex mb-2">
    <div></div>
    <span class="spacer"></span>
    <button *ngIf="route | hasPermission:'canCreate'" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Dashboard/Setup/createcontrolTypes">
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
        Create New
    </button>
</div>

<div>
  <app-atlas-table [columns]="columns" [dataSource]="data"
                   [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                   [hasDeleteIcon]="true"
                   [hasPreviewIcon]="true"
                   [onEdit]="getEditUrl.bind(this)"
                [onDelete]="onDelete.bind(this)"
                      >



  </app-atlas-table>
</div>

<ng-template #content5 let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4 class="modal-title"> Are you sure you want to remove the CIS Asset Type</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
      </button>
  </div>
  <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="delete()">Yes</button>
</ng-template>
<button id="btnDelete" style="display: none;" type="hidden" class="btn btn-primary"  (click)="open(content5)">Launch demo modal</button>
