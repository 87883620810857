<div class="row">
  <div class="main-card mb-3 card">
    <div class="card-body">
      <form (ngSubmit)="createControl()">
        <div class="row">
          <div class="position-relative mb-3 col-md-4">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Document</mat-label>
              <mat-select (selectionChange)="loadChapter()" [(ngModel)]="documentId" name="documentId">
                <mat-option *ngFor="let doc of documentlist" [value]="doc.id">
                  {{doc.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="position-relative mb-3 col-md-4">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Chapter</mat-label>
              <mat-select [(ngModel)]="chapterId" name="chapterId">
                <mat-option *ngFor="let chapter of chaptersList" [value]="chapter.id">
                  {{chapter.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="position-relative mb-3 col-md-6">
            <mat-form-field style="width: 100%;">
              <mat-label>Title/Control</mat-label>
              <input matInput placeholder="Control" name="title" [(ngModel)]="title" required>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="position-relative mb-3 col-md-12">
            <app-atlas-html-editor [descriptionHTMLFormate]="objectives" [height]="120" [Label]="lblObjective"
              [onCreateCommand]="onCreate.bind(this)" [onChange]="objectiveChange.bind(this)"
              [placeholder]="lblObjective">
            </app-atlas-html-editor>
          </div>
        </div>
        <div class="row">
          <div class="position-relative mb-3 col-md-12">
            <app-atlas-html-editor [descriptionHTMLFormate]="description" [height]="120" [Label]="lblDescription"
              [onCreateCommand]="onCreate.bind(this)" [onChange]="descriptionChange.bind(this)"
              [placeholder]="lblDescription"> </app-atlas-html-editor>
          </div>
        </div>
        <div class="row">
          <div class="position-relative mb-3 col-md-12">
            <app-atlas-html-editor [descriptionHTMLFormate]="otherInformation" [height]="120"
              [Label]="lblOtherInformation" [onCreateCommand]="onCreate.bind(this)"
              [onChange]="otherInformationChange.bind(this)"
              [placeholder]="lblOtherInformation"></app-atlas-html-editor>
          </div>
        </div>

        <div class="row">
          <div class="position-relative mb-3 col-md-12">
            <app-atlas-html-editor [descriptionHTMLFormate]="auditingGuideLines" [height]="120"
              [Label]="lblAuditingGuidelines" [onCreateCommand]="onCreate.bind(this)"
              [onChange]="auditingGuideLinesChange.bind(this)"
              [placeholder]="lblAuditingGuidelines"></app-atlas-html-editor>
          </div>
        </div>
        <hr>

        <div class="row">
          <div class="position-relative mb-3 col-md-4">
            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">
              <mat-label>Control Types</mat-label>
              <mat-select [(ngModel)]="SelectedControlTypes" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of controlTypelist" [value]="option.id">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Primary Objective -->
          <div class="position-relative mb-3 col-md-4">

            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">
              <mat-label>Primary Objectives</mat-label>
              <mat-select [(ngModel)]="SelectedPrimaryObjectives" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of primaryObjectiveslist" [value]="option.id">{{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>


        <div class="row">
          <!-- control function -->
          <div class="position-relative mb-3 col-md-4">


            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">

              <mat-label>Control Functions</mat-label>
              <mat-select [(ngModel)]="SelectedControlFunction" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of controlFunctionslist" [value]="option.id">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Suggested Controls -->
          <div class="position-relative mb-3 col-md-4">


            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">

              <mat-label>Suggested Controls</mat-label>
              <mat-select [(ngModel)]="SelectedSuggestedControls" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of suggestedControlslist" [value]="option.id">{{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- suggested control ownership -->

          <div class="position-relative mb-3 col-md-4">


            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">

              <mat-label>Suggested Control Ownership</mat-label>
              <mat-select [(ngModel)]="SelectedSuggestedControlOwnerShips" [ngModelOptions]="{standalone: true}"
                multiple>
                <mat-option *ngFor="let option of suggestedControlOwnershiplist" [value]="option.id">
                  {{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <!-- Scf domains -->
          <div class="position-relative mb-3 col-md-4">


            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">

              <mat-label>SCF Domains</mat-label>
              <mat-select [(ngModel)]="SelectedScfDomains" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of scfDomainsList" [value]="option.id">{{option.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- cis level -->
          <div class="position-relative mb-3 col-md-4">


            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">

              <mat-label>CIS Levels</mat-label>
              <mat-select [(ngModel)]="SelectedCISLevels" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of cisLevellist" [value]="option.id">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!--cis asset-->

          <div class="position-relative mb-3 col-md-4">


            <mat-form-field appearance="fill" style="width: -webkit-fill-available;">

              <mat-label>CIS Asset Types</mat-label>
              <mat-select [(ngModel)]="SelecteCISAssetsTypes" [ngModelOptions]="{standalone: true}" multiple>
                <mat-option *ngFor="let option of cisAssetTypelist" [value]="option.id">{{option.name}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>









        <button class="mt-1 btn btn-success btn-atlas">Submit</button>
        <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>


      </form>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 100000;">
  <mat-spinner></mat-spinner>
</div>
<div>
</div>