import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MaturityService } from 'src/app/Services/maturity.service';
import { NotificationService } from 'src/app/Services/notification.service';
import { TitleService } from 'src/app/Services/title.service';

@Component({
  selector: 'app-create-maturity',
  templateUrl: './create-maturity.component.html',
  styleUrls: ['./create-maturity.component.sass']
})
export class CreateMaturityComponent {

  maturityForm: UntypedFormGroup;
  maturityId: number;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private maturityService: MaturityService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private titleService: TitleService

  ) {
    this.titleService.setTitle("New Maturity Level");
    this.titleService.setBackButtonUrl("/Audit/Maturity");
    this.maturityForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      level: ['', [Validators.required]],

    });

    this.route.params.subscribe((qp) => {
      let maturityid = qp.id;
      this.maturityId = maturityid;

      if (this.maturityId) {
        this.titleService.setTitle("Edit Maturity Level");
        this.maturityService.get(this.maturityId).subscribe((data) => {
          this.maturityForm.patchValue({
            name: data.name,
            level: data.level

          });
        });
      }
    });
  }

  MoveBack() {
    this.router.navigate(["/Audit/Maturity"]);

  }


  createMaturity() {
    if (this.maturityForm.invalid) {
      return;
    } else if (this.maturityId) {
      this.maturityService
        .update({
          name: this.maturityForm.get("name").value,
          id: this.maturityId,
          level: this.maturityForm.get("level").value,

        })
        .subscribe(
          () => {
            this.notify.showInfo("Updated Successfully", "Maturity");
            this.router.navigate(["/Audit/Maturity"]);
          },
          () => {
            this.notify.showError("Error while updating", "Scf Domain");
            this.router.navigate(["/Audit/Maturity"]);
          }
        );
    } else {
      this.maturityService
        .create({
          name: this.maturityForm.get("name").value,
          level: this.maturityForm.get("level").value,

        })
        .subscribe(
          () => {
            this.notify.showSuccess("Created Successfully", "Maturity");
            this.router.navigate(["/Audit/Maturity"]);
          },
          () => {
            this.notify.showError("Error while creating", "Maturity");
            this.router.navigate(["/Audit/Maturity"]);
          }
        );
    }

  }
  ngOnDestroy() {
    this.titleService.setBackButtonUrl("");
    this.titleService.setTitle("");
  }
}
