import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../environments/environment";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Component, Input } from "@angular/core";
import showDownloadStatus from "src/app/Services/download-status-services";
import { ProgressStatus } from "src/app/Common/models/progress-status.model";
import FileInfo from "src/app/Common/models/files";
import { FormsSection } from "src/app/Enum/permission-type";
@Component({
  selector: "app-atlas-files-list",
  templateUrl: "./atlas-files-list.component.html",
  styleUrls: ["./atlas-files-list.component.scss"],
})
export class AtlasFilesListComponent  {
  @Input() filesList: FileInfo[] = [];
  @Input() visible = false;
  @Input() EntityName: string;
  @Input() visibleView = false;
  @Input() view = (file: FileInfo) =>
    console.log("Not implemented view handle");
  @Input() visibleDownload = false;
  @Input() download = (file: FileInfo) => this.downloadFileUsingLink(file);
  @Input() visibleLink = false;
  @Input() link = (file: FileInfo) => this.saveLinkToClipboard(file);
  @Input() visibleDelete = true;
  @Input() delete = (file: FileInfo) =>
    console.log("Not implemented delete handle");
  @Input() isDeletable = (file: FileInfo): boolean => false;
  @Input() align = "";
  @Input() metaTags: string[] = [];
  @Input() Section: FormsSection = FormsSection.None;
   sectionEnum=  FormsSection;
  @Input() formGroupData: UntypedFormGroup;
  @Input() isEditForm: boolean = false;
  @Input() isFromMailItemDetails: boolean = false;

  informationDialogOpened = false;
  @Input() control: UntypedFormControl;
  @Input() iconsDisabledClass: string;
  constructor(private snackBar: MatSnackBar) {}
  
  downloadAll(fileList): void {
    fileList.forEach((element) => {
      this.downloadFileUsingLink(element);
    });
  }

  count = 0;
  downloadURL = function downloadURL(url) {
    let hiddenIFrameID = "hiddenDownloader" + this.count++;
    let iframe = document.createElement("iframe");
    iframe.id = hiddenIFrameID;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
    iframe.src = url;
  };

  showDownloadStatus(fileName: string, progressStatus: ProgressStatus) {
    showDownloadStatus(progressStatus, this.snackBar, fileName);
  }

  saveLinkToClipboard(file: FileInfo) {
    if (this.EntityName == "MailLog") {
     
      this.snackBar.open("The file link was copied to your clipboard.", null, {
        duration: 2000,
      });
    }

    if (this.EntityName == "Accounting") {
      
      this.snackBar.open("The file link was copied to your clipboard.", null, {
        duration: 2000,
      });
    }

    if (this.EntityName == "Events") {
      
      this.snackBar.open("The file link was copied to your clipboard.", null, {
        duration: 2000,
      });
    }
  }

  downloadFileUsingLink(fileLink: FileInfo): void {
    const url = this.EntityName 
      ? environment.apiUrl + `Files/DownloadForSection/${this.EntityName}/${fileLink.id}`
      : environment.apiUrl + `Files/Download/${fileLink.id}`;
    
    // Create a temporary <a> element and trigger the download
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank'; // To open each file in a new tab
    anchor.download = ''; // Optional: specify a filename
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  private createAnchor(fileLink: string): HTMLAnchorElement {
    let link = document.createElement("a");
    link.style.display = "none";
    return link;
  }

  onInformationDialogOpened() {
    this.informationDialogOpened = true;
  }

  onInformationDialogOpenedClosed() {
    this.informationDialogOpened = false;
  }
}
