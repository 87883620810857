import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from 'src/app/Services/notification.service';
import { RolesServices } from 'src/app/Services/roles-services';
import { ControlDocumentsServices } from 'src/app/Services/control-documents.services';
import { HasPermissionPipe } from 'src/app/Shared/pipes/HasPermissionPipe';
import { Reminder } from 'src/app/Shared/commen/reminder';
import { PublishControlDocumentServices } from 'src/app/Services/publish-control-document';
import { UserService } from 'src/app/Services/user-services';
import { TitleService } from 'src/app/Services/title.service';
@Component({
  selector: 'app-publish-control-document-create',
  templateUrl: './publish-control-document-create.component.html',
  styleUrls: ['./publish-control-document-create.component.sass'],
  providers: [
    HasPermissionPipe,
  ],
})
export class PublishControlDocumentCreateComponent implements OnInit {
  publishDocumentForm: UntypedFormGroup;
  roleid: number;
  controlDocuments: any;
  route: any;
  everyone = 'everyone';
  isAllSelected : boolean = false;
  reminders: any[];
  users: any[];
  submitting: boolean = false;
  publishDoucmentId: any;
  controlDocumentId: any;
  constructor(private formBuilder: UntypedFormBuilder,
    private publishControlDocumentServices: PublishControlDocumentServices,
    private controlDocumentService: ControlDocumentsServices,
    private router: Router,
    private locationService: Location,
    private notify: NotificationService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private hasPermissionPipe: HasPermissionPipe,
    private titleService: TitleService
  )
   {
    this.titleService.setTitle('Publish New Document');
    this.titleService.setBackButtonUrl('/Documentation/PublishedControlDocument');
    this.activeRoute.params.subscribe(qp => {
      let publishDoucmentId = qp.id;
      this.publishDoucmentId = publishDoucmentId;
      this.publishDocumentForm = this.formBuilder.group({
        controlDocumentId: ['', Validators.required],
        assignUser: [[], Validators.required],
        reminder: [[], Validators.required],
        description: ['']
      });
      this.activeRoute.queryParams.subscribe(params => {
        this.controlDocumentId = params['controlDocumentId'];
        this.publishDocumentForm.patchValue({
          controlDocumentId: this.controlDocumentId,
        })
      });
      if (publishDoucmentId) {
        this.titleService.setTitle('Edit Published Document');
        this.publishControlDocumentServices.get(publishDoucmentId).subscribe(data => {
          this.publishDocumentForm.patchValue({
            controlDocumentId: data.controlDocumentId,
            assignUser: data.assignee,
            reminder: data.reminder,
            description: data.description
          })
        })
      }
    })

  }

  ngOnInit(): void {
    this.getAllControlDocuments();
    this.getAllUsers();
    this.route = this.activeRoute.snapshot;
    this.reminders = Object.values(Reminder)
      .filter(value => typeof value === 'number')
      .map(value => ({
        value: value,
        label: `${value} Days`
      }));
  }

  MoveBack() {
    this.locationService.back();
  }
  ngOnDestroy() {
    this.titleService.setBackButtonUrl("");
    this.titleService.setTitle("");
  }
  getAllUsers() {
    this.userService.getAllUsers().subscribe(data => {
      this.users = data
    })
  }
  getAllControlDocuments(){
     this.controlDocumentService.getAllMajorAndMinor().subscribe(data =>{
      this.controlDocuments = data;
    })
  }

  createPublishDocument() {
    if (this.publishDocumentForm.invalid) {
      return;
    }
    this.submitting = true;
    if (this.publishDoucmentId) {
      this.publishControlDocumentServices.update({
        id: this.publishDoucmentId,
        controlDocumentId: this.publishDocumentForm.get('controlDocumentId').value,
        description: this.publishDocumentForm.get('description').value,
        reminder: this.publishDocumentForm.get('reminder').value,
        assignee: this.publishDocumentForm.get('assignUser').value,
      }).subscribe(data => {
        this.submitting = false;
        this.notify.showSuccess("Updated Successfully", "published control document");
        this.router.navigate(['/Documentation/PublishedControlDocument']);
      },
        (error) => {
          this.submitting = false;
          this.notify.showError("Error while creating", "published control document");
          this.router.navigate(['/Documentation/PublishedControlDocument']);
        })
    }
    else {
      this.publishControlDocumentServices.create({
        controlDocumentId: this.publishDocumentForm.get('controlDocumentId').value,
        description: this.publishDocumentForm.get('description').value,
        reminder: this.publishDocumentForm.get('reminder').value,
        assignee: this.publishDocumentForm.get('assignUser').value,
      }).subscribe(data => {
        this.submitting = false;
        this.notify.showSuccess("Created Successfully", "published control document");
         this.router.navigate(['/Documentation/PublishedControlDocument']);
      },
        (error) => {
          this.submitting = false;
          this.notify.showError("Error while creating", "published control document");
          this.router.navigate(['/Documentation/PublishedControlDocument']);
        })
    }
  }

  onSelectionChange(event: any) {
    const selectedValues = event.value;
   this.updateSelection(selectedValues);
  }

  private updateSelection(selectedValues: any[]) {
    const allUserIds = this.users.map(user => user.id);
    const isEveryoneSelected = selectedValues.includes(this.everyone);
    if(isEveryoneSelected && this.isAllSelected){
      this.publishDocumentForm.get('assignUser')?.setValue([], { emitEvent: false });
      this.isAllSelected = false;
    }
    else if (isEveryoneSelected) {
        this.publishDocumentForm.get('assignUser')?.setValue([...allUserIds], { emitEvent: false });
        this.isAllSelected = true;
    } else {
        this.publishDocumentForm.get('assignUser')?.setValue(selectedValues.filter(value => value !== this.everyone), { emitEvent: false });
        this.isAllSelected = false;

    }
  }

}