import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CisAssetTypeService } from 'src/app/Services/CIS-AssetType.services';
import { NotificationService } from 'src/app/Services/notification.service';
import { TitleService } from 'src/app/Services/title.service';

@Component({
  selector: 'app-create-asset-type',
  templateUrl: './create-asset-type.component.html',
  styleUrls: ['./create-asset-type.component.sass']
})
export class CreateAssetTypeComponent {


  heading = 'CIS Asset Type';
  subheading = 'CIS Asset Type that you will be uploading inside system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  cisAssetTypeForm: UntypedFormGroup;
  cisAssetTypeId: number;
  constructor(private formBuilder: UntypedFormBuilder,
    private cisAssetTypeService: CisAssetTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location,
    private titleService: TitleService
  ) {
    this.titleService.setTitle('New CIS Asset Type');
    this.titleService.setBackButtonUrl('/Dashboard/Setup/assetTypes');
    this.cisAssetTypeForm = this.formBuilder.group({
      name: ['', [Validators.required]],



    });


    this.route.params.subscribe(qp => {
      let cisAssetTypeid = qp.id;
      this.cisAssetTypeId = cisAssetTypeid;

      if (this.cisAssetTypeId) {
        this.titleService.setTitle('Edit CIS Asset Type');
        this.cisAssetTypeService.get(this.cisAssetTypeId).subscribe(data => {
          this.cisAssetTypeForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }

  MoveBack() {
    this.locationService.back();
  }

  createDocumentType() {
    if (this.cisAssetTypeForm.invalid) {
      return
    }
    else if (this.cisAssetTypeId) {
      this.cisAssetTypeService.update({
        name: this.cisAssetTypeForm.get('name').value,
        id: this.cisAssetTypeId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Asset Type')
        this.router.navigate(['/Dashboard/Setup/assetTypes']);
      },
        error => {
          this.notify.showError('Error while updating', 'Asset Type')
          this.router.navigate(['/Dashboard/Setup/assetTypes']);
        }
      )
    }
    else {
      this.cisAssetTypeService.create({
        name: this.cisAssetTypeForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Asset Type')
        this.router.navigate(['/Dashboard/Setup/assetTypes'])
      },
        error => {
          this.notify.showError('Error while creating', 'Asset Type')
          this.router.navigate(['/Dashboard/Setup/assetTypes']);
        })
    }

  }
  ngOnDestroy() {
    this.titleService.setBackButtonUrl('');
    this.titleService.setTitle("");
  }
}
