import { DatePipe, Location } from "@angular/common";
import { Component } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/Services/notification.service";
import { UserService } from "../../Services/user-services";
import { RolesServices } from "src/app/Services/roles-services";

@Component({
  selector: "app-create-users",
  templateUrl: "./create-users.component.html",
  styleUrls: ["./create-users.component.scss"],
})
export class CreateUsersComponent {
  userId: number;
  userForm: UntypedFormGroup;
  DOB?: string = null;
  password: string;
  cpassword: string;
  hasSpecialCharacter: boolean = false;
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumeric: boolean = false;
  displayPasswordValidation: boolean = false;
  roles: any[];
  checkPassword: boolean = false;
  duplicateUser: boolean = false;
  userName: string;
  isLoading = false;
  submitted = false;
  editForm = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private datePipe: DatePipe,
    private notify: NotificationService,
    private locationService: Location,
    private roleServices: RolesServices
  ) {
    this.isLoading = true;
    this.userForm = this.formBuilder.group({
      email: ["", [Validators.required], [this.emailValidator]],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      roleIds: [[""], [Validators.required]],
    });
    this.fetchRoles();
    console.log(this.roles)
  }
  ngOnInit(): void {
    this.route.params.subscribe((qp) => {
      let userId = qp.id;
      this.userId = userId;
      this.userForm = this.formBuilder.group({
        email: ["", [Validators.required]],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        roleIds: ["", [Validators.required]],
      });
      if (userId) {
        this.editForm = true;
        this.userForm.get('email').disable();
        this.userService.getUser(userId).subscribe((data) => {
        this.isLoading = false;
        this.userForm.patchValue({
          email: data.email,
          name: data.name,
          roleIds: data.roleIds.map(String),
          firstName: data.firstName,
          lastName: data.lastName
      });
    });
      }
      else {
        this.isLoading = false;
        this.userForm.get('firstName').clearValidators();
        this.userForm.get('firstName').updateValueAndValidity();
        this.userForm.get('lastName').clearValidators();
        this.userForm.get('lastName').updateValueAndValidity();
      }
    });
  }
  MoveBack() {
    this.locationService.back();
  }
  assignedEmail = null;
  createUser() {
    if (this.userForm.invalid) {
      return
    }
    this.isLoading = true;
    this.submitted = true;
    const selectedRoleIds = this.userForm.get('roleIds').value; 
    const selectedRoleNames = selectedRoleIds
                              .map(id => this.roles.find(role => role.id === id)?.name)
                              .filter(name => !!name); 
    if (this.userId) {
      this.userService
        .update({
          email: this.userForm.get("email").value,
          id: this.userId,
          firstName: this.userForm.get("firstName").value,
          lastName: this.userForm.get("lastName").value,
          roleNames: selectedRoleNames,
          roleIds: this.userForm.get("roleIds").value
        })
        .subscribe((data) => {
          this.notify.showInfo("Updated Successfully", "User");
          this.router.navigate(["/Settings/Users"]);
        },
          (error) => {
            this.notify.showError("Error while updating", "User");
            this.isLoading = true;
            this.submitted = true;
          });
    }
  }

  fetchRoles() {
    this.isLoading = true;

    this.roleServices.getAllRoles().subscribe((data) => {
      this.isLoading = false;
      this.roles = data;
      this.isLoading = false;
    });
  }

  emailValidator(control: AbstractControl): Promise<any> | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return Promise.resolve({ invalidEmail: true });
    }

    return Promise.resolve(null);
  }



  sendInvitation() {
    this.submitted = true;
    if (this.userForm.invalid) {
      this.submitted = false;
      return
    }
    this.isLoading = true;
    this.userService.sendMailInvitation({
      email: this.userForm.get("email").value,
      roleIds: this.userForm.get("roleIds").value
    })
      .subscribe((data) => {
        this.notify.showInfo("Invitation Sent Successfully", "User");
        this.router.navigate(["/Settings/Users"]);
        this.isLoading = false;
      },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Error", "User");
          this.router.navigate(["/Settings/Users"]);

        }
      )
  }

}
