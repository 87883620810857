import { FilterType } from './Enums';

export const FilterFormConfig = {
  [FilterType.Audits]: {
    status: null,
    startDateTo: null,
    startDateFrom: null,
    endDateTo: null,
    endDateFrom: null,
    documentIds: null,
    customerIds: null
  },
  [FilterType.ControlDocuments]: {
    status: null,
    version: null,
    userIds: null,
    departmentId: null,
    category: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.DPA]: {
    title: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.TPM]: {
    vendorName: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.ROPA]: {
    processingName: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.DataRight]: {
    requestReferenceNumber: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.IncidentRegistry]: {
    reporter: null,
    createdDateFrom: null,
    createdDateTo: null,
  },
  [FilterType.Questions]: {
    controlTypeIds: null,
    cisAssetTypeIds: null,
    controlFunctionIds: null,
    PrimaryObjectiveIds: null,
    suggestedControlIds: null,
    ScoIds: null,
    scfDomainIds: null,
    cisLevelIds: null,
    chapterIds: null,
    documentId: null
  }
};
