import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrimaryObjectivesService } from "src/app/Services/primary-objectives.service";
import { TitleService } from "src/app/Services/title.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-primary-objectives",
  templateUrl: "./primary-objectives.component.html",
  providers:[HasPermissionPipe]
})
export class PrimaryObjectivesComponent implements OnInit {
  heading = "Primary Objectives";
  subheading = "Primary Objectives of Control inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  constructor(
    private primaryObjectivesService: PrimaryObjectivesService,
    private router: Router,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private hasPermission: HasPermissionPipe,
    private titleService: TitleService
  ) {
    this.titleService.setTitle("Primary Objectives");
  }

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  documentId: any;
  route: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;

  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionDeleteIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionEditIcon",
      columnWidth: "2%",
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];

  ngOnInit(): void {
    this.getAll();
    this.route = this.activeRoute.snapshot;
  }

  getAll() {
    this.primaryObjectivesService.getAll().subscribe((res) => {
      this.data = res;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "/Dashboard/Setup/createPrimaryObjective/" + event.id,
    ]);
  }

  onDelete(event) {
    this.documentId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.primaryObjectivesService
          .delete(this.documentId)
          .subscribe((data) => {
            this.getAll();
            this.modalService.dismissAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  delete() {
    this.primaryObjectivesService.delete(this.documentId).subscribe((data) => {
      this.getAll();
      this.modalService.dismissAll();
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle("");
  }
}
