import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RichTextEditorComponent } from "@syncfusion/ej2-angular-richtexteditor";
import { ListHelper } from "src/app/ListHelperCLasses/ListHelper";
import { ChapterService } from "src/app/Services/chapter.service";
import { CommonService } from "src/app/Services/common-service.service";
import { DocumentService } from "src/app/Services/document.service";
import { TitleService } from "src/app/Services/title.service";

@Component({
  selector: "app-create-or-edit-chapter",
  templateUrl: "./create-or-edit-chapter.component.html",
  styleUrls: ["./create-or-edit-chapter.component.sass"],
})
export class CreateOrEditChapterComponent implements OnInit {
  chapterForm: UntypedFormGroup;
  chapterId: number;
  chaptersList: ListHelper[] = [];
  documentList: ListHelper[] = [];
  descriptionHTMLFormate: string = "";
  public height: number = 200;
  public tools = {
    items: [
      'Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen'
    ]
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private chapterService: ChapterService,
    private router: Router,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private commonService: CommonService,
    private locationService: Location,
    private titleService: TitleService
  ) {
    this.titleService.setTitle("New Chapter");
    this.titleService.setBackButtonUrl("/Dashboard/Setup/chapters");
    this.chapterForm = this.formBuilder.group({
      title: ["", [Validators.required]],
      documentId: ["", [Validators.required]],
      chapterId: ["", [Validators.required]],
      parentId: [],
    });

    this.route.params.subscribe((qp) => {
      let chapterid = qp.id;
      this.chapterId = chapterid;

      if (this.chapterId) {
      this.titleService.setTitle("Edit Chapter");
        this.chapterService.get(this.chapterId).subscribe((data) => {
          this.chapterService.getList(data.documentId).subscribe((result) => {
            this.chaptersList = result;
          });
          this.chapterForm.patchValue({
            title: data.title,
            documentId: data.documentId,
            parentId: data.parentId,
            chapterId: data.chapterId,
          });
          this.descriptionHTMLFormate = data.description;
        });

      }
    });
  }
  public onCreate(): void {
  }
  onChange(event) {
    this.descriptionHTMLFormate = event;
  }
  @ViewChild('rteObj') rteObj: RichTextEditorComponent;
  public actionCompleteHandler(e: any): void {
    if (e.requestType === 'SourceCode') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.add('e-overlay');
    } else if (e.requestType === 'Preview') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.remove('e-overlay');
    }
  }
  ngOnInit(): void {
    this.documentService.getList().subscribe((result) => {
      this.documentList = result;
      this.route.queryParamMap.subscribe((data) => {
        if (data.get("docId")) {
          this.chapterForm.get("documentId").setValue(data.get("docId"));
        }
      });
    });
  }

  loadChapters(event) {
    this.chapterService.getList(event.value).subscribe((result) => {
      this.chaptersList = result;
    });
  }

  createsChapter() {
    if (this.chapterForm.invalid) {
      console.log("invalid form");
    } else if (this.chapterId) {
      this.chapterService
        .update({
          title: this.chapterForm.get("title").value,
          documentId: this.chapterForm.get("documentId").value,
          parentId: this.chapterForm.get("parentId").value,
          chapterId: this.chapterForm.get("chapterId").value,
          description: this.descriptionHTMLFormate,
          id: this.chapterId,
        })
        .subscribe((data) => {
          this.router.navigate(["/Dashboard/Setup/chapters"]);
        });
    } else {
      this.chapterService
        .create({
          title: this.chapterForm.get("title").value,
          documentId: this.chapterForm.get("documentId").value,
          parentId: this.chapterForm.get("parentId").value,
          chapterId: this.chapterForm.get("chapterId").value,
          description: this.descriptionHTMLFormate,
        })
        .subscribe((data) => {
          this.commonService.documentId =
            this.chapterForm.get("documentId").value;
          this.router.navigate(["/Dashboard/Setup/chapters"]);
        });
    }

  }
  MoveBack() {
    this.locationService.back();
  }
  ngOnDestroy() {
    this.titleService.setBackButtonUrl("");
    this.titleService.setTitle("");
  }
}
