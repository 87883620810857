<app-filter-function [FilterType]="filterTypeEnum" [canCreate]="currentRoute | hasPermission:'canCreate'">
  (tableSettingPopup)="tableSettingPopup()"></app-filter-function>
<div *ngIf="!showRevisionHistory">
  <app-atlas-table [columns]="columns" [dataSource]="data" [customColumnWidths]="true"
    [customPageSizeOptions]="customPageSizeOptions" [hasDeleteIcon]="true" [hasPreviewIcon]="true"
    [onEdit]="getEditUrl.bind(this)" [pageChange]="pageChangeHandler.bind(this)" [hasServerPaging]="true"
    [hasPaginator]="false" [totalItems]="totalItems" [onDelete]="onDelete.bind(this)"
    [onOpen]="onOpenDetails.bind(this)">
  </app-atlas-table>
</div>
<app-control-documents-details *ngIf="openDetails" [event]="selectedControlDocument"
  [onClose]="onCloseDetail.bind(this)" style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-control-documents-details>
<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>
<app-revision-history *ngIf="showRevisionHistory" [controlDocumentId]="controlDocumentId"
  [onClose]="onClose.bind(this)"></app-revision-history>

<app-compare-version-popup *ngIf="showComparisonPopup" [controldocumentId]="controldocumentId"
  [onClose]="onClose.bind(this)"></app-compare-version-popup>

<app-table-setting *ngIf="isTableSettingPopupOpen" [columns]="columns" [settingType]="settingType"
  (closePopup)="onCloseTableSettingPopup()" (saveSettings)="onSaveSettings()"></app-table-setting>